.holiday h3{
    text-align: center;
}
.holiday .bold{
    font-weight: bold;
    width: 100%;
}
.holiday .fullwidth{
    width: 100%;
}
.holiday.top{
    margin-top: 2%;
  }
.holiday .th{
    border: 1px solid black;
    border-collapse: collapse;
  }
 