.ag-header-cell-text {
    font-family: poppins, regular;
    color: rgb(116, 115, 121);
    text-transform: uppercase;
    letter-spacing: 0px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    line-height: normal !important;
    white-space: normal !important;
    word-break: break-word !important;
}

.ag-cell-value {
    /* line-height: unset !important;
    white-space: normal !important;
    word-break: break-all !important; */
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.87);
    font-family: Poppins;
    font-weight: 400;
    padding-top: "25%";
}

.number-input {   
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.number-input-error {   
    color: rgb(244, 67, 54);
}