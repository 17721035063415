.ag-header-cell-text {
    font-family: poppins, regular;
    color: rgb(116, 115, 121);
    text-transform: uppercase;
    letter-spacing: 0px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    border-bottom: '1px solid rgba(224, 224, 224, 1)';
    /* line-height: normal !important;
    white-space: normal !important;
    word-break: break-word !important; */
}
.noteditable{
  cursor: not-allowed;
  pointer-events:'none';
}
.ag-cell-wrap-text {
  word-break: break-word;
}

.ag-cell-value {
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.87);
    font-family: Poppins;
    font-weight: 400;
    height: 'auto';
    font-size: '10px';
    line-height: normal;
}
.ag-cell{
  border-right: 1px solid #dde2eb !important;
}
.toLink {
    text-decoration: 'underline';
    white-space: 'normal';
    word-wrap: 'break-word';
    cursor: 'pointer';
    height: 'auto';
    font-size: '10px';
  }
  .rag-amber{
    background-color: rgb(245, 198, 125) !important;
  }
  .highlight-age-cell{
    background-color: red !important;
  }
  .ag-header-cell-resize {
    z-index: 0 !important;
  }
  