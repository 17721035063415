.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: '#1c1939';
  overflow: hidden;
  z-index: 30000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bellend {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.modalContainer {
  width: 20vw;
  height: auto;
  border-radius: 12px;
  background-color: #1c1939;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  float: right;
  padding: 10px;
  padding-right: 0px;
}

.modalContainerEachRow {
  border-radius: 12px;
  background-color: #05040b;
  float: left;
  padding: 10px;
  margin-bottom: 10px;
}

.viewButtonParent {
  display: flex;
  justify-content: end;
}
.viewButtonStyle {
  align-self: center;
  background: #05040b;
  color: #ffffff !important;
}

.p{
  font-size: 0.4rem;
  color: white;
}
.close{
  padding-right: 10px;
}

.syncLogo {
  color: white;
  font-size: small;
  height: fit-content;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  color: white;
}

.timeRow {
  font-size: 0.6rem;
  color: grey;
}



.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.TodaysDate {
  font-size: 0.7rem;
  color: white;
}

.modalContainer .body {
  flex: 50%;
  font-size: 0.6rem;
  color: white;
  float: left;
  overflow-wrap: anywhere;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listClass{
  overflow: auto;
  height: 557.3px;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
