/* YourStyles.module.css */

.checkbox {
    position: relative;
    padding-left: 30px; /* Adjust the spacing for the checkbox */
    cursor: pointer;
  }
  
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid rgb(118, 118, 118); /* Border color of the checkbox */
    border-radius: 3px; /* Optional: Add rounded corners */
  }
  
  .checkbox input:checked + .checkmark {
    background-color: #322b7c; /* Blue background color */
  }
  
  .checkbox input:checked + .checkmark:after {
    content: '\2713'; /* Unicode character for check mark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px; /* Adjust the font size for a squarish checkmark */
    line-height: 1; /* Ensure the line height is minimal for a squarish shape */
    color: #fff; /* White color of the checkmark */
  }
  